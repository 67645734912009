<template lang="pug">
#tariff-extend
  h1.main-page-title Информация о компании
  alert-message(v-if="managerCompanyInfo?.isBlocked" type="error" label="Аккаунт компании был заблокирован" :message="managerCompanyInfo?.reason")
  alert-message(v-if="managerCompanyInfo?.isTest" label="Служебный аккаунт - принадлежит внутренним сотрудникам")
  user-company-info
  users-info
  connected-company-tariff
  edit-company-tariff

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UsersInfo from "@/components/pages/admin/extend/UsersInfo.vue";
import UserCompanyInfo from "@/components/pages/admin/extend/UserCompanyInfo.vue";
import EditCompanyTariff from "@/components/pages/admin/extend/EditCompanyTariff.vue";
import ConnectedCompanyTariff from "@/components/pages/admin/extend/ConnectedCompanyTariff.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";
import { useSuperAccount } from "~/use/superAccount/useSuperAccount";

export default defineComponent({
  name: "PageTariffChanger",
  components: {
    AlertMessage,
    UsersInfo,
    UserCompanyInfo,
    EditCompanyTariff,
    ConnectedCompanyTariff,
  },
  setup() {

    const { managerCompanyInfo } = useSuperAccount()

    return {
      managerCompanyInfo,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#tariff-extend {
  @include adaptive-view;

  display: flex;
  flex-flow: column;
  gap: 32px;
}
</style>
