<template lang="pug">
settings-panel(v-loading="!tariffsList.length || !tariffsOptionsList.length")
  template(#header) Продление тарифа
  ui-tabs(:model-value="selectedTariffId" :tabs="tabs" @update:model-value="switchTariff")
  template(v-for="tariff of tariffsList" :key="tariff.id")
    .info-table(v-show="tariff.id === selectedTariffId")
      .tariff-changer

        .card-item
          .label Название тарифа
          .value
            span {{ tariff.title }}
            ui-tag.deleted-tariff(v-if="tariff.isDeleted" type="danger") Удаленный тариф
          template(v-if="tariff.id === CONSTRUCT")
            .label(style="margin-top: 16px;") Действия с опциями
            .value
              .link(@click="selectAllOptions") Выбрать все опции
              .link(@click="clearAllOptions") Очистить выбор
              .link(v-for="t of tariffsList.filter(e => e.id !== CONSTRUCT)" @click="selectAsTariff(t)") Тариф: {{ t.title }}
              .link(v-if="clientTariffInfo?.tariff?.id === CONSTRUCT" @click="selectAsPrevious") Подключенный тариф

        .card-item(v-if="tariff.id !== CONSTRUCT")
          .label Подключенные опции
          .value(v-for="option of tariff.options" :key="`${tariff.id}-${option.id}`") {{ option.id === UID ? setUsersNumber(tariff.id) : option.title }}

        .card-item(v-else)
          .label Подключенные опции
          checkbox-group(v-model="selectedOptions" :options="tariffsOptionsList" :disabled-options="[UID]" id="tariff-options")

        .card-item
          .label(v-if="tariff.id === CONSTRUCT") Количество пользователей
          .value(v-if="tariff.id === CONSTRUCT" style="margin-bottom: 8px;")
            number-input(v-model="userCount" :min="1")
          .label Дата окончания тарифа
          .value
            date-picker(v-model="dateTariffEnd" format="DD.MM.YYYY" :disabled-date="disabledDate" :shortcuts="shortcuts")

        .card-item
          .label Действия
          .value
            ui-button.fit-content(@click="extend") Продлить тариф
            .error(v-if="showError && selectedTariffId === tariff.id && !dateTariffEnd") Выберите дату окончания тарифа

ExtendTariffDialog(
  v-model:show="showDialog"
  :is-constructor="selectedTariffId === CONSTRUCT"
  :tariff="tariffsList.find(e => e.id === selectedTariffId)"
  :options="tariffsOptionsList"
  :selected-options="selectedOptions"
  :date-end="dateTariffEnd"
  :user-count="userCount"
)
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { addDays, addMonths } from "date-fns";
import { storeToRefs } from "pinia";

import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import DatePicker from "@/components/ui/picker/DatePicker.vue";
import NumberInput from "@/components/ui/form/input/NumberInput.vue";
import UiTabs from "@/components/ui/tab/UiTabs.vue";
import useManualsStore from "@/stores/manuals/useManualsStore";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";

import { UID, CONSTRUCT } from "@/const/tariffs";
import UiTag from "@/components/ui/tag/UiTag.vue";

export default defineComponent({
  name: "EditCompanyTariff",
  components: {
    UiTag,
    UiTabs,
    NumberInput,
    DatePicker,
    UiButton,
    CheckboxGroup,
    SettingsPanel,
    ExtendTariffDialog: defineAsyncComponent(() => import("@/components/pages/admin/extend/ExtendTariffDialog.vue")),
  },
  setup() {

    const selectedOptions = ref<number[]>([14]);
    const selectedTariffId = ref<number>(2);
    const dateTariffEnd = ref('');
    const userCount = ref(1);

    const showDialog = ref(false);
    const showError = ref(false);

    const tabs = ref([]);

    const manualsStore = useManualsStore();
    const { tariffsList, tariffsOptionsList } = storeToRefs(manualsStore);

    const managerStore = useTmManagerStore();
    const { clientTariffInfo } = storeToRefs(managerStore);

    function switchTariff(id: number) {
      selectedTariffId.value = id;
      userCount.value = getUsersNumber(id);
    }

    // const { managerCompanyId } = useSuperAccount();
    // watch(managerCompanyId, () => {
    //   if (managerCompanyId.value) {
    //     selectedOptions.value = [14]
    //     selectedTariffId.value = 2
    //     dateTariffEnd.value = null
    //     userCount.value = 1
    //     showError.value = false
    //   }
    // })

    const shortcuts = [
      { text: '7 дней', value: addDays(new Date(), 7) },
      { text: '1 месяц', value: addMonths(new Date(), 1) },
      { text: '6 месяцев', value: addMonths(new Date(), 6) },
      { text: '12 месяцев', value: addMonths(new Date(), 12) },
    ]

    function setUsersNumber(id: number) {
      switch(id) {
        case 2: return `3 пользователя`;
        case 4: return `10 пользователей`;
        default: return `1 пользователь`;
      }
    }

    function getUsersNumber(id: number) {
      switch(id) {
        case 2: return 3;
        case 4: return 10;
        default: return 1;
      }
    }

    function extend() {
      showError.value = false;
      if (!dateTariffEnd.value) {
        showError.value = true;
      } else {
        showDialog.value = true;
      }
    }

    function selectAllOptions() {
      selectedOptions.value = tariffsOptionsList.value.map(e => e.id)
    }

    function clearAllOptions() {
      selectedOptions.value = []
    }

    function selectAsTariff(t: any) {
      selectedOptions.value = t.options.map(e => e.id)
    }

    function selectAsPrevious() {
      selectedOptions.value = clientTariffInfo.value?.tariffOptions || []
    }

    function disabledDate(time: Date) {
      return new Date(time).getTime() < new Date().getTime()
    }

    function onTariffResponse() {
      tabs.value = tariffsList.value.map(e => ({ name: e.title, key: e.id }))
      selectedTariffId.value = clientTariffInfo.value?.tariff?.id || tabs.value[0].key
      userCount.value = getUsersNumber(selectedTariffId.value);
    }

    if (!tariffsList.value.length) {
      manualsStore.manuals.getTariffsList().then(onTariffResponse)
    } else {
      onTariffResponse()
    }

    if (!tariffsOptionsList.value.length) manualsStore.manuals.getTariffsOptionsList()

    return {
      tabs,
      tariffsList,
      tariffsOptionsList,
      shortcuts,
      showDialog,
      showError,
      selectedOptions,
      selectedTariffId,
      dateTariffEnd,
      userCount,
      clientTariffInfo,
      switchTariff,
      disabledDate,
      extend,
      setUsersNumber,
      selectAllOptions,
      clearAllOptions,
      selectAsTariff,
      selectAsPrevious,
      UID,
      CONSTRUCT,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/companyInfo';

.tariff-list {
  display: flex;
  flex-flow: column;
  gap: 32px;
}

.info-table {
  min-height: 440px;
}

.value {
  flex-flow: row wrap;
}

.deleted-tariff {
  margin-top: 24px;
  width: auto !important;
  justify-content: center;
}

.link {
  cursor: pointer;
  color: var(--default-blue-color);
  margin-right: 16px;
  margin-top: 4px;

  &:hover {
    font-weight: bold;
  }
}

.error {
  font-size: 12px;
  line-height: 16px;

  color: var(--main-red-color);
}
</style>
