<template lang="pug">
settings-panel(v-loading="usersLoading")
  template(#header) Пользователи
  template(#headerActions)
    icon-button(
      :size="16"
      :icon="UiIconNames.Icon_Edit"
      tooltip-title="Редактировать данные пользователей"
      @click="goToPage"
    )
  .info-table(v-if="!usersList.length")
    .error(v-if="usersError") При получении списка пользователей произошла ошибка
    .message(v-else) В компании нет зарегистрированных пользователей
  .info-table(v-else="!usersList.length")
    .users-info
      .label ФИО
      .label Контактный телефон
      .label Контактный email
      .label Доступ
    .users-info(v-for="user of usersList" :key="user.id")
      .value {{ user.fio || '-' }}
      .value {{ user.phone || '-' }}
      .value {{ user.login || '-' }}
      .value(:class="user.role?.id === ROLE_BLOCKED_USER && 'blocked'") {{ user.role?.title || '-' }}
</template>

<script lang="ts">
import { Tabs } from "@/router/tabs";
import { defineComponent } from 'vue';
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import useUsersStore from "@/stores/users/useUsersStore";
import IconButton from "@/components/ui/button/IconButton.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import { ROLE_BLOCKED_USER } from "@/const/roles";

export default defineComponent({
  name: "UsersInfo",
  components: {
    IconButton,
    SettingsPanel,
  },
  setup() {

    const router = useRouter();
    const usersStore = useUsersStore();

    const {
      usersLoading,
      usersError,
      usersList,
    } = storeToRefs(usersStore);

    function goToPage() {
      router.push({ name: Tabs.Settings.UsersManagement });
    }

    return {
      usersList,
      usersError,
      usersLoading,
      goToPage,
      UiIconNames,
      ROLE_BLOCKED_USER,
    }
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/companyInfo';

.blocked {
  color: var(--main-red-color);
}
</style>
