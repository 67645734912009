<template lang="pug">
settings-panel
  template(#header) Подключенный тариф
  .info-table
    .tariff-connected(:class="isConstruct && 'construct'")
      .label Название тарифа
      .label(v-if="isConstruct") Подключенные опции
      .label Макс. кол-во пользователей
      .label Дата окончания тарифа

    .tariff-connected(:class="isConstruct && 'construct'")
      .value {{ clientTariffInfo?.tariff?.title || '-' }}
      .card-item(v-if="isConstruct")
        .value(v-for="option of options" :key="option.id") {{ option.title }}
      .value {{ clientTariffInfo?.possibleUsersCount || '-' }}
      .value {{ clientTariffInfo?.dateEndTariff ? clientTariffInfo?.dateEndTariff + ' МСК' : '-' }}

</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { storeToRefs } from "pinia";

import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import useManualsStore from "@/stores/manuals/useManualsStore";

import { CONSTRUCT } from "@/const/tariffs";

export default defineComponent({
  name: "ConnectedCompanyTariff",
  components: {
    SettingsPanel,
  },
  setup() {

    const tenmonManagerStore = useTmManagerStore();
    const { clientTariffInfo } = storeToRefs(tenmonManagerStore);

    const manualsStore = useManualsStore();
    const { tariffsOptionsList } = storeToRefs(manualsStore);

    const options = computed(() => clientTariffInfo.value?.tariffOptions.length ? tariffsOptionsList.value.filter(t => clientTariffInfo.value?.tariffOptions.includes(t.id)) : [])
    const isConstruct = computed(() => clientTariffInfo.value?.tariff?.id === CONSTRUCT)

    return {
      options,
      clientTariffInfo,
      isConstruct,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/companyInfo';
</style>
